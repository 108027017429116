import {Category} from '@src/models/category';
import {api} from './client';
import {AdminInterest} from '@src/models/adminInterests';

export namespace CategoryApi {
  export interface UpdateCategoryReq {
    categoryId: string;
    name: AdminInterest;
  }
  export interface AddCategoryReq {
    name: AdminInterest;
  }
  export interface UpdateSubcategoryReq {
    categoryId: string;
    subCategoryId: string;
    name: AdminInterest;
  }
  export interface AddSubcategoryReq {
    categoryId: string;
    name: AdminInterest;
  }
  export interface UpdateSubsubcategoryReq {
    subCategoryId: string;
    subSubCategoryId?: string;
    name: AdminInterest;
  }
  export interface AddSubsubcategoryReq {
    subcategoryId: string;
    name: AdminInterest;
  }

  interface GetCategoriesParams {
    recursively?: boolean,
    allTranslations?: boolean,
  }

  export const getCategories = async (params: GetCategoriesParams) => {
    const res = await api.get<Category[]>(
      'categories', {params: params}
    );
    return res.data;
  };
  export const removeCategory = async (categoryId: string) => {
    const res = await api.delete(`categories/${categoryId}`);
    return res.data;
  };

  export const updateCategory = async (body: UpdateCategoryReq) => {
    const {categoryId, ...obj} = body;
    const res = await api.put(`categories/${categoryId}`, obj);
    return res.data;
  };

  export const addCategory = async (body: AddCategoryReq) => {
    const res = await api.post(`categories`, body);
    return res.data;
  };

  export const removeSubcategory = async (
    categoryId: string,
    subcategoryId: string
  ) => {
    const res = await api.delete(
      `categories/${categoryId}/subcategories/${subcategoryId}`
    );
    return res.data;
  };
  export const updateSubcategory = async (body: UpdateSubcategoryReq) => {
    const {categoryId, subCategoryId, ...obj} = body;
    const res = await api.put(
      `categories/${categoryId}/subcategories/${subCategoryId}`,
      obj
    );
    return res.data;
  };

  export const addSubcategory = async (body: AddSubcategoryReq) => {
    const {categoryId, ...obj} = body;
    const res = await api.post(`categories/${categoryId}/subcategories`, obj);
    return res.data;
  };

  export const removeSubsubcategory = async (
    subcategoryId: string,
    subsubcategoryId: string
  ) => {
    const res = await api.delete(
      `subcategories/${subcategoryId}/subSubcategories/${subsubcategoryId}`
    );
    return res.data;
  };

  export const updateSubsubcategory = async (body: UpdateSubsubcategoryReq) => {
    const {subSubCategoryId, subCategoryId, ...obj} = body;
    const res = await api.put(
      `subcategories/${subCategoryId}/subsubcategories/${subSubCategoryId}`,
      obj
    );
    return res.data;
  };

  export const addSubsubcategory = async (body: AddSubsubcategoryReq) => {
    const {subcategoryId, ...obj} = body;
    const res = await api.post(
      `subcategories/${subcategoryId}/subsubcategories`,
      obj
    );
    return res.data;
  };
}
