import styled from 'styled-components';
import {CustomSearchBar} from '@src/common/filters/CustomSearchBar';
import {useTranslation} from 'next-i18next';
import {CustomSearchSelect} from '@src/common/filters/CustomSearchSelect';
import {useMemo, useState} from 'react';
import {devices} from '@src/common/screenSizes';
import {useQuery} from 'react-query';
import {useDebounce} from '@src/utils/useDebounce';
import {GSearch, Place} from '@src/common/inputs/GSearch';
import {CategoryApi} from '@src/api/category-api';
import {categoryService} from '@src/features/auth/logic/category-service';
import {ClubList} from '../components/ClubList';
import {SubCategory, SubSubCategory} from '@src/models/category';
import {Spinner} from '@src/common/ui/Spinner';
import {SpinnerWrapped} from '@src/common/ui/SpinnerWrapped';

const PickCLubWrapper = styled.div`
  width: 462px;
  margin: 15px auto;
  @media ${devices.sm} {
    width: 100%;
    max-width: 462px;
  }
`;
const ChosenType = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #1a1818;
  margin-top: 30px;
`;

export const ClubListScreen = ({}) => {
  const {t} = useTranslation();
  const [categoryId, setCategoryId] = useState('ALL');
  const [subCategoryData, setSubCategoryData] = useState<
    SubCategory[] | undefined
  >(undefined);
  const [subSubCategoryData, setSubSubCategoryData] = useState<
    SubSubCategory[] | undefined
  >(undefined);
  const [subCategoryId, setSubCategoryId] = useState('ALL');
  const [subSubCategoryId, setSubSubCategoryId] = useState('ALL');
  const [search, setSearch] = useState('');
  const [address, setAddress] = useState<Place>();

  const {data, isLoading} = useQuery(
    ['categories'],
    () => CategoryApi.getCategories({recursively: true}),
    {
      staleTime: 1 * 60 * 1000,
    }
  );
  const categoriesData = useMemo(() => {
    if (!data) {
      return [];
    }
    return categoryService.mapCategories(data);
  }, [data]);

  const handleNewSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleNewSelectInputCategory = (e: string) => {
    setCategoryId(e);
    setSubCategoryId('ALL');
    setSubSubCategoryId('ALL');
    if (categoriesData)
      setSubCategoryData(
        categoryService.getSubcategoriesForCategory(categoriesData, e)
      );
  };
  const handleNewSelectInputSubCategory = (e: string) => {
    setSubCategoryId(e);
    setSubSubCategoryId('ALL');
    if (subCategoryData)
      setSubSubCategoryData(
        categoryService.getSubSubcategoriesForSubcategory(categoriesData, e)
      );
  };
  const handleNewSelectInputSubSubCategory = (e: string) => {
    setSubSubCategoryId(e);
  };

  const debouncedSearch = useDebounce(search, 500);
  const debouncedAddress = useDebounce(address, 500);

  const handleNewAddress = (e: Place) => {
    setAddress(e);
  };
  const handleResetAddress = () => {
    setAddress({
      latitude: '',
      longitude: '',
      address: '',
      country: '',
      countryShortName: '',
    });
  };

  if (isLoading) {
    return (
      <Box>
        <SpinnerWrapped />
      </Box>
    );
  }

  return (
    <PickCLubWrapper>
      <CustomSearchBar
        onChange={handleNewSearchInput}
        value={search}
        placeHolder={
          t('common:search-club-by-name-president-categories') as string
        }
      />
      <CustomSearchSelect
        onChange={handleNewSelectInputCategory}
        options={[
          {
            id: 'ALL',
            label: t(`user-profile:club-list:all-categories`),
            value: 'ALL',
          }, //@ts-ignore
        ].concat(categoriesData)}
        value={categoryId}
        placeholder={t(`user-profile:club-list:all-categories`)}
      />
      {categoryId !== 'ALL' && (
        <CustomSearchSelect
          onChange={handleNewSelectInputSubCategory}
          options={[
            {
              id: 'ALL',
              label: t(`user-profile:club-list:all-categories`),
              value: 'ALL',
            }, //@ts-ignore
          ].concat(subCategoryData)}
          value={subCategoryId}
          placeholder={t(`user-profile:club-list:all-categories`)}
        />
      )}
      {subCategoryId !== 'ALL' && (
        <CustomSearchSelect
          onChange={handleNewSelectInputSubSubCategory}
          options={[
            {
              id: 'ALL',
              label: t(`user-profile:club-list:all-categories`),
              value: 'ALL',
            }, //@ts-ignore
          ].concat(subSubCategoryData)}
          value={subSubCategoryId}
          placeholder={t(`user-profile:club-list:all-categories`)}
        />
      )}

      {/* <CustomSearchSelect
        onChange={() => handleNewSelectInput}
        options={
          categoriesData.find((c) => c.id === categoryId)?.subcategories || []
        }
        placeholder={t(`user-profile:club-list:all-categories`)}
      /> */}

      <GSearch
        type="city"
        inputType="secondary"
        value={address}
        label={''}
        handleNewPlace={handleNewAddress}
        onResetFilter={handleResetAddress}
      ></GSearch>
      <ChosenType>{`${t(`user-profile:club-list:CLUBS`)}`}</ChosenType>
      <ClubList
        categoryId={categoryId}
        subCategoryId={subCategoryId}
        subSubCategoryId={subSubCategoryId}
        search={debouncedSearch}
        address={debouncedAddress as Place}
        sort={'CREATED_AT'}
      />
    </PickCLubWrapper>
  );
};

const Box = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
