import {
  Category as ApiCategory,
  ClubObjectCategory,
} from '@src/models/category';

export interface Category {
  id?: string;
  label: string;
  value: string;
  subCategories: SubCategory[];
}
export interface SubSubCategory {
  id?: string;
  label: string;
  value: string;
}
export interface SubCategory {
  id?: string;
  value: string;
  label: string;
  subSubCategories: SubSubCategory[];
}

class CategoryService {
  mapCategories(data: ApiCategory[]) {
    const newData = data.map<Category>((category) => {
      if (
        category.subcategories === null ||
        category.subcategories === undefined
      ) {
        return {
          id: category.id,
          value: category.id,
          label: category.name,
          subCategories: [],
        };
      }
      const newSubCategories = category?.subcategories.map((subcategory) => {
        const newSubSubcategories = subcategory.subSubcategories.map(
          (subsubcategory) => {
            return {
              id: subsubcategory.id,
              value: subsubcategory.id,
              label: subsubcategory.name,
            };
          }
        );
        return {
          id: subcategory.id,
          value: subcategory.id,
          label: subcategory.name,
          subSubCategories: newSubSubcategories,
        };
      });
      return {
        id: category.id,
        value: category.id,
        label: category.name,
        subCategories: newSubCategories,
      };
    });
    return newData;
  }

  getSubcategoriesForCategory(categories: Category[], category: string) {
    let subCategories: Array<SubCategory> = [];
    categories.forEach((cat: Category) => {
      if (cat.value === category) {
        subCategories = cat.subCategories;
      }
    });
    return subCategories;
  }
  getSubSubcategoriesForSubcategory(
    categories: Category[],
    subCategory: string
  ) {
    let subSubCategories: Array<SubSubCategory> = [];
    categories.forEach((cat: Category) => {
      cat.subCategories.forEach((sub: SubCategory) => {
        if (sub.value === subCategory) {
          subSubCategories = sub.subSubCategories;
        }
      });
    });
    return subSubCategories;
  }
  getCategoryLabelByValue(categories: Category[], value: string) {
    if (!value) {
      return '';
    }
    let label = '';
    categories.forEach((cat: Category) => {
      if (cat.value === value) {
        label = cat.label;
      }
      cat.subCategories.forEach((sub: SubCategory) => {
        if (sub.value === value) {
          label = sub.label;
        }
        sub.subSubCategories.forEach((subSub: SubSubCategory) => {
          if (subSub.value === value) {
            label = subSub.label;
          }
        });
      });
    });

    return label;
  }
  getMostDetailedCategory(categories: ClubObjectCategory[]) {
    try {
      let finalString = '';
      if (categories.length === 0) {
        return '-';
      }
      for (let index = 0; index < categories.length; index++) {
        const category = categories[index] as ClubObjectCategory;

        if (category.secondSubcategory) {
          finalString +=
            `${category.secondSubcategory.name}` +
            (index < categories.length - 1 ? ', ' : '');
        } else if (category.subcategory) {
          finalString +=
            `${category.subcategory.name}` +
            (index < categories.length - 1 ? ', ' : '');
        } else {
          if (category.category === null) {
            continue;
          }
          finalString +=
            `${category.category.name}` +
            (index < categories.length - 1 ? ', ' : '');
        }
      }
      return finalString;
    } catch (e) {
      console.error('CategoryService::getMostDetailedCategory:', e);
      return '';
    }
  }
}

export const categoryService = new CategoryService();
