import styled from 'styled-components';
import {Spinner} from './Spinner';

const SpinnerWrapper = styled.div`
  margin: auto;
  margin-top: 50px;
`;

export const SpinnerWrapped = () => {
  return (
    <SpinnerWrapper>
      <Spinner />
    </SpinnerWrapper>
  );
};
