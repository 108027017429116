import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

const Information = styled.p`
  margin: auto;
  margin-top: 50px;
  text-align: center;
`;

export const NoDataInformation = () => {
  const {t} = useTranslation();
  return <Information>{t('common:noData')}</Information>;
};
