import styled from 'styled-components';
import Image from 'next/image';
import {useQuery} from 'react-query';
import {Spinner} from './Spinner';
import {parseError} from '@src/utils/error';
import {imagesService} from '@src/features/images/logic/images-service';
import {HTMLAttributes, useState} from 'react';
import Modal from './Modal';

type ApiImageProps = {
  imageId?: string;
  width?: number;
  height?: number;
  spinnerContainerStyle?: HTMLAttributes<HTMLDivElement>['style'];
  fill?: boolean;
  isPrevieable?: boolean;
  addEmptyImage?: boolean;
} & React.HTMLProps<HTMLImageElement>;

const SpinnerContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;
const ErrorMessage = styled.div``;

export const ApiImage = ({
  imageId,
  width,
  height,
  style,
  isPrevieable = false,
  spinnerContainerStyle,
}: ApiImageProps) => {
  const {data, isLoading, isError, error} = useQuery(
    ['images', imageId],
    async () => await imagesService.getFile(imageId as string),
    {
      staleTime: 1 * 60 * 1000,
      enabled: imageId !== undefined && imageId !== '' && imageId !== null,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  const [showModal, setShowModal] = useState(false);

  if (imageId === undefined || imageId === '' || imageId === null || !data) {
    return (
      <>
        {width && height ? (
          <Image
            src={'/images/mock-logo.png'}
            alt="profile pic"
            style={style}
            width={width}
            height={height}
          ></Image>
        ) : (
          <Image
            src={'/images/mock-logo.png'}
            alt="profile pic"
            style={style}
            fill
          ></Image>
        )}
      </>
    );
  }

  if (isLoading) {
    return (
      <SpinnerContainer style={spinnerContainerStyle}>
        <Spinner
          width={
            spinnerContainerStyle?.width
              ? (spinnerContainerStyle.width as number)
              : undefined
          }
          height={
            spinnerContainerStyle?.height
              ? (spinnerContainerStyle.height as number)
              : undefined
          }
        />
      </SpinnerContainer>
    );
  }
  if (isError) {
    return <ErrorMessage>{parseError(error).message}</ErrorMessage>;
  }

  return (
    <>
      {width && height ? (
        <Image
          onClick={() => isPrevieable && setShowModal(true)}
          src={data}
          alt="profile pic"
          style={{cursor: isPrevieable ? 'pointer' : 'default', ...style}}
          width={width}
          height={height}
        ></Image>
      ) : (
        <Image
          onClick={() => isPrevieable && setShowModal(true)}
          src={data}
          alt="profile pic"
          style={{cursor: isPrevieable ? 'pointer' : 'default', ...style}}
          fill
        ></Image>
      )}
      {isPrevieable && (
        //@ts-ignore unneeded props are needed
        <Modal show={showModal} onClose={setShowModal}>
          <img src={data} style={{maxHeight: '80vh'}} />
        </Modal>
      )}
    </>
  );
};
