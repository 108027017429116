import {ForwardedRef, forwardRef, ComponentProps, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import Select from 'react-select';

const InputContainer = styled.div`
  grid-area: ${(props) =>
    props.style?.gridArea ? props.style?.gridArea : 'unset'};
`;

const Title = styled.p`
  margin: 0;
`;

const SelectCointainer = styled.div`
  margin-top: ${(props) => props.theme.spacing.s}px;
  margin-bottom: ${(props) => props.theme.spacing.s}px;

  font-size: 0.875rem;
  line-height: 1.3125;
  font-weight: 500;
  color: ${(props) => props.style?.color || props.theme.colors.neutral100};
  background: ${(props) => props.theme.colors.primary500};
  width: 100%;
  cursor: pointer;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 20px;
  word-break: break-word;
  text-overflow: ellipsis;
  text-align: center;
  option {
    max-width: 100%;
    text-overflow: ellipsis;
    span {
      max-width: 300px;
      background-color: red;
    }
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.colors.neutral100};
    opacity: 1;
  }
`;
type options = {
  label: string;
  value: string;
};
export interface AppSelectProps {
  value?: string;
  options: options[];
  onChange: (values: string) => void;
  placeholder: string;
  title?: string;
}

export function CustomSearchSelect({
  options,
  value = undefined,
  onChange,
  placeholder,
  title = '',
  ...rest
}: AppSelectProps) {
  const theme = useTheme();
  const [selectImg, setSelectImg] = useState('/icons/arrow-white.svg');

  const handleToogleSelectImg = () => {
    if (selectImg === '/icons/arrow-white.svg') {
      setSelectImg('/icons/arrow-up-white.svg');
    } else {
      setSelectImg('/icons/arrow-white.svg');
    }
  };

  return (
    <InputContainer {...rest}>
      <Title className="text-m14">{title}</Title>
      <SelectCointainer>
        <Select<options, false>
          id="react-select"
          onChange={(e) => {
            if (e?.value) {
              onChange(e.value);
            }
          }}
          value={options.find((item) => item.value === value)}
          options={options}
          placeholder={placeholder ?? ''}
          styles={{
            control: (provided) => ({
              ...provided,
              width: '100%',
              color: 'white',
              background: theme.colors.primary500,
              border: 'none',
              padding: '5px 15px 5px 10px',
              borderRadius: 10,
              outline: 'none',
              boxShadow: 'none',
            }),
            input: (provided) => ({
              ...provided,
              border: 'none',
              color: 'white',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            dropdownIndicator: () => ({
              color: 'white',
            }),
            placeholder: (provided) => ({
              ...provided,
              border: 'none',
              color: 'white',
            }),
            singleValue: (provided) => ({
              ...provided,
              color: 'white',
            }),
            menu: (provided) => ({
              ...provided,
              background: theme.colors.primary500,
              border: 'none',
              margin: 0,
              color: 'white',
            }),

            valueContainer: (provided) => ({
              ...provided,
              background: theme.colors.primary500,
              border: 'none',
              marginLeft: 36,
            }),
            container: (provided) => ({
              ...provided,
              background: 'white',
              border: 'none',
            }),

            option: (provided) => ({
              ...provided,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              color: 'white',
              textOverflow: 'ellipsis',

              background: theme.colors.primary500,
            }),
          }}
        />
      </SelectCointainer>
    </InputContainer>
  );
}
