import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

interface ErrorInformationI {
  customInformation?: string;
}

const Information = styled.p`
  margin: auto;
  width:fit-content;
  margin-top: 50px;
  color:${(props) => props.theme.colors.primary400}
`;

export const ErrorInformation = ({customInformation}: ErrorInformationI) => {
  const {t} = useTranslation();

  return (
    <Information>
      {t(customInformation ? customInformation : 'error:default')}
    </Information>
  );
};
